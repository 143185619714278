body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* Playground: https://www.w3schools.com/cssref/playdemo.asp?filename=playcss_accent-color */
.sidebar {
    background-color: rgba(255, 255, 255, 1);
    color: #fff;
    padding: 6px 6px;
    z-index: 1;
    position: absolute;
    margin: 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.sidebarSection {
    margin: 1px 1px 1px 1px;
}
.sidebarSectionTitle {
    margin: 0 10px 0 0;
    width: 90px
}

.statusTxt {
    color: #fff;
    padding: 6px 12px;
    /*font-family: monospace;*/
    z-index: 1;
    position: absolute;
    bottom: 20px;
    width: 20%;
    max-height: 95%;
    right: 0;
    margin: 12px;
    opacity: 0.8;
    border-radius: 10px;
    white-space: pre-wrap;
}

.elevChart {
    color: #fff;
    padding: 6px 12px;
    /*font-family: monospace;*/
    z-index: 1;
    position: absolute;
    bottom: 20px;
    width: 79%;
    max-height: 95%;
    left: 0;
    margin: 12px;
    opacity: 0.8;
    white-space: pre-wrap;
}

